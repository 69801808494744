import loginRepo from './modules/loginRepo';
import userRepo from './modules/userRepo';
import questionnaireRepo from './modules/questionnaireRepo';

const repoList = {
	login: loginRepo,
	user: userRepo,
	questionnaire: questionnaireRepo
};

export const RepoFactory = {
	get: name => repoList[name]
};