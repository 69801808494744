import { RepoFactory } from '../../../api_repository/RepositoryFactory';

const questionnaireRepo = RepoFactory.get('questionnaire');

const submitForm = (context, payload) => new Promise((resolve,reject) => {	
	setTimeout(() => {
		questionnaireRepo.submitForm(payload)
			.then((response) => {
        context.commit('SAVE_HEALTH_FORM', response);
				resolve(response);
			})
			.catch((err) => {
				reject(err.response);
			});
	}, 2000);
});

const updateForm = (context, payload) => new Promise((resolve,reject) => {	
	let form_id = payload.id;
	let data = payload.data;

	setTimeout(() => {
		questionnaireRepo.updateForm(form_id, data)
			.then((response) => {
        context.commit('SAVE_HEALTH_FORM', response);
				resolve(response);
			})
			.catch((err) => {
				reject(err.response);
			});
	}, 2000);
});

const getAnsweredQuestionaires = (context, payload) => new Promise((resolve, reject) => {
	setTimeout(() => {
		questionnaireRepo.getUserAnsweredQuestionnaires(payload)
			.then(response => {
				context.commit('GET_ANSWERED_FORMS', response);
				resolve(response.data);
			}).catch(err => {
				reject(err.response);
			});
	}, 2000);
});

const getAnsweredForm = (context, payload) => new Promise((resolve, reject) => {
	setTimeout(() => {
		questionnaireRepo.getUserAnsweredForm(payload)
			.then(response => {
				context.commit('GET_ANSWERED_FORMS', response);
				resolve(response.data);
			}).catch(err => {
				reject(err.response);
			});
	}, 2000);
});

export default{
	submitForm,
	updateForm,
	getAnsweredForm,
	getAnsweredQuestionaires
};