// Store functionality
import { createStore } from 'vuex';
import actions from './actions';
import getters from './getters';
import modules from './modules';
import mutations from './mutations';
import state from './state';

// Create a new store
const store = createStore({
	actions,
	getters,
	modules,
	mutations,
	state
});

export default store;