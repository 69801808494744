import { createRouter, createWebHistory } from '@ionic/vue-router';
import Tabs from '../views/Tabs.vue'

const routes = [
  {
    path: '/login',
    component: () => import('@/views/login.vue')
  },
  {
    path: '/onboard',
    component: () => import('@/views/onboard.vue')
  },
  {
    path: '/health-checklist-form',
    name: 'declarationForm',
    component: () => import('@/views/mainform.vue')
  },
  {
    path: '/update-health-form/:form_id',
    name: 'updateDeclarationForm',
    props: true,
    component: () => import('../views/updateform.vue')
  },
  {
    path: '/view-health-form/:form_id',
    name: 'viewDeclarationForm',
    props: true,
    component: () => import('../views/viewform.vue')
  },
  {
    path: '/',
    redirect: '/dashboard',
    component: Tabs,
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        component: () => import('@/views/dashboard.vue')
      },
      {
        path: 'profile',
        name: 'profile',
        component: () => import('@/views/profile.vue')
      },
      {
        path: 'history',
        name: 'history',
        component: () => import('@/views/history.vue')
      },
      {
        path: 'emergency',
        name: 'emergency',
        component: () => import('@/views/emergency.vue')
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
