const AUTH_SUCCESS = (state, payload) => {
	state.isAuthenticated = payload;
};

const SET_CURRENT_USER = (state, payload) => {
	const stringified_payload = JSON.stringify(payload);
	state.currUser = stringified_payload;
};

const SET_TOKEN = (state, payload) => {
	const stringified_payload = JSON.stringify(payload);
	state.currUser = stringified_payload;
};

export default {
	AUTH_SUCCESS,
	SET_TOKEN,
	SET_CURRENT_USER
};