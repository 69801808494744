const SAVE_HEALTH_FORM = (state, payload) => {
	const stringified_payload = JSON.stringify(payload);
	state.questionnaire = stringified_payload;
};

const GET_ANSWERED_FORMS = (state, payload) => {
	const stringified_payload = JSON.stringify(payload);
	state.answeredForms = stringified_payload;
};

export default {
	SAVE_HEALTH_FORM,
	GET_ANSWERED_FORMS
};