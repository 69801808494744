<template>
  <ion-app>
    <ion-router-outlet />
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { defineComponent } from 'vue';
// import { LocalNotifications } from '@ionic-native/local-notifications';

// LocalNotifications.schedule({
//   notifications: [
//     {
//       title: "Health Monitoring App",
//       text: "Good Day! Don't forget to fill out your monitoring form today",
//       sound: "file://sound.mp3",
//       trigger: { every: 'minute', count: 1 }
//     }
//   ]
// });

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet
  },
});
</script>
<style>
  .native-input {
    padding-left: 0 !important;
    padding-right: 0 !important;
    font-family: "Roboto Regular";
  }

  .content-subheader::part(native) .item-inner {
    border: 0;
  }
  
  .message-subtitle-container p {
      padding: 0;
  }
  
  .toast-custom {
    transform: translateY(-57px) !important;
  }


  @media (min-width: 768px) {
    .alert-container .alert-wrapper {
      width: 650px;
      max-width: 650px;
    }

    .message-subtitle-container p {
      padding: 0 5px;
    }
  }
</style>