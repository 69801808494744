import { RepoFactory } from '../../../api_repository/RepositoryFactory';

const loginRepo = RepoFactory.get('login');

const doLoginWithGoogle = (context) => new Promise((resolve,reject) => {
  // context.commit('AUTH_REQUEST');	
	const newWindow = openWindow('', 'message');

	setTimeout(() => {
		loginRepo.googleLogin()
			.then((response) => {
				let url_account_selection = response.data + '&prompt=select_account';

				newWindow.location.href = url_account_selection;
				context.commit('AUTH_SUCCESS',true);
				resolve(response);
			})
			.catch((err) => {
				reject(err.response);
			});
	}, 2000);
});

const verifyAuth = (context) => new Promise((resolve, reject) => {
	setTimeout(() => {
		loginRepo.verifyAuth().then((response) => {
				context.commit('SET_CURRENT_USER', response.data);
				context.commit('AUTH_SUCCESS',true);
				resolve(response.data);
			})
			.catch((err) => {
				reject(err.response);
			});
	}, 2000); 
});

// const setCurrUser = (context,payload) => {
// 	context.commit('SET_CURRENT_USER',payload);
// };

function openWindow (url, title, options = {}) {
	if (typeof url === 'object') {
		options = url
		url = ''
	}

	options = { url, title, width: 600, height: 720, ...options }

	const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screen.left
	const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screen.top
	const width = window.innerWidth || document.documentElement.clientWidth || window.screen.width
	const height = window.innerHeight || document.documentElement.clientHeight || window.screen.height

	options.left = ((width / 2) - (options.width / 2)) + dualScreenLeft
	options.top = ((height / 2) - (options.height / 2)) + dualScreenTop

	const optionsStr = Object.keys(options).reduce((acc, key) => {
		acc.push(`${key}=${options[key]}`)
		return acc
	}, []).join(',')

	const newWindow = window.open(url, title, optionsStr)

	if (window.focus) {
		newWindow.focus()
	}

	return newWindow
}

export default{
	doLoginWithGoogle,
	verifyAuth
};