import Repository from '../Repository';
import { setup } from 'axios-cache-adapter'
import CryptoJS from 'crypto-js';
import VueCookies from 'vue-cookies'

export default {
	googleLogin() {
		return Repository.get('google-login');
	},
	verifyAuth() {
		const ciphertext = VueCookies.get('misc');

		if (ciphertext) {
			var bytes  = CryptoJS.AES.decrypt(ciphertext, process.env.VUE_APP_DCRPT_KEY);
			var decryptedData = bytes.toString(CryptoJS.enc.Utf8);
			var decode = JSON.parse(decryptedData);
			var token = decode.token;
		}

		const baseDomain = process.env.VUE_APP_API_URL || 'https://hayaapi.perahub.com.ph/api';
		const baseURL = `${baseDomain}`;
		const api = setup({
			// `axios` options
			baseURL: baseURL,
			// `axios-cache-adapter` options
			headers: {
				'Accept': 'application/json',
				'Authorization': `Bearer ${token}`
			},
			cache: {
				maxAge: 259200 //3days
			}
		})
		
		return api.get('verify-auth');
	}
};