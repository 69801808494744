import Repository from '../Repository';

export default {
	updateProfile(id, payload) {
		return Repository.post(`update-profile/${id}`, payload);
	},
	getUserNotifID(id) {
		return Repository.get(`get-uuid/${id}`);
	},
	addUserNotifID(payload) {
		return Repository.post(`add-uuid`, payload);
	},
	updateUserNotifID(id, payload) {
		return Repository.post(`update-uuid/${id}`, payload);
	},
	logoutUser() {
		return Repository.post('logout');
	}
};