import Repository from '../Repository';

export default {
	submitForm(payload) {
		return Repository.post('submit-health-form', payload);
	},
	updateForm(form_id, payload) {
		return Repository.post(`/update-health-form/${form_id}`, payload);
	},
	getUserAnsweredQuestionnaires(user_id) {
		return Repository.get(`get-user-answered-forms/${user_id}`);
	},
	getUserAnsweredForm(id) {
		return Repository.get(`get-user-answered-form/${id}`);
	},
};