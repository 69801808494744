import { RepoFactory } from '../../../api_repository/RepositoryFactory';

const userRepo = RepoFactory.get('user');


const updateProfile = (context, payload) => new Promise((resolve,reject) => {
  // context.commit('AUTH_REQUEST');
	let user_id = payload.id;
	let data = payload.data;
	
	setTimeout(() => {
		userRepo.updateProfile(user_id, data)
			.then((response) => {
				resolve(response);
			})
			.catch((err) => {
				reject(err.response);
			});
	}, 2000);
});

const addUserNotifID = (context, payload) => new Promise((resolve, reject) => {
	setTimeout(() => {
		userRepo.addUserNotifID(payload)
			.then((response) => {
				resolve(response);
			}).catch((err) => {
				reject(err.response);
			});
	}, 2000);
});

const getUserNotifID = (context, payload) => new Promise((resolve, reject) => {
	setTimeout(() => {
		userRepo.getUserNotifID(payload)
			.then((response) => {
				context.commit('GET_USER_UUID', response);
				resolve(response.data);
			}).catch((err) => {
				reject(err.response);
			});
	}, 2000);
});

const updateUserNotifID = (context, payload) => new Promise ((resolve, reject) => {
  let id = payload.id;
	let data = payload.data;
  
  setTimeout(() => {
    userRepo.updateUserNotifID(id, data)
      .then(response => {
				resolve(response);
      }).catch(err => {
        reject(err.response);
      });
  });
});

const logout = () => new Promise((resolve, reject) => {
	setTimeout(() => {
		userRepo.logoutUser().then(response => {
			resolve(response);
		}).catch(err => {
			reject(err.response);
		});
	}, 2000);
});

export default{
	updateProfile,
	addUserNotifID,
	getUserNotifID,
	updateUserNotifID,
	logout
};