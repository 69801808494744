// import Vue from 'vue';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import VueCookies from 'vue-cookies'
// import store from '../store/index';
// import router from 'vue-router';

const baseDomain = process.env.VUE_APP_API_URL || 'https://hayaapi.perahub.com.ph/api';

const baseURL = `${baseDomain}`;

let config = {
	baseURL
};

let myAPI = axios.create(config);



myAPI.interceptors.request.use(
	(config) => {
		//config.headers['Access-Control-Allow-Origin'] = '*';
		config.headers['Accept'] = 'application/json';

		const cipherData = VueCookies.get('misc');
		let bytes = "";
		let decryptedData = "";

		if (cipherData) {
			bytes  = CryptoJS.AES.decrypt(cipherData, process.env.VUE_APP_DCRPT_KEY);
			decryptedData = bytes.toString(CryptoJS.enc.Utf8);
		}
		
		if (decryptedData) {
			let decode = JSON.parse(decryptedData);
			let token = decode.token;

			config.headers['Authorization'] = `Bearer ${token}`;
		}

		return config;
	}
);

myAPI.interceptors.response.use(function (response) {
	return response;
}, function (error) {
	let err = error.response;
	let isLogin = error.response.config.url.indexOf('login');
	//console.log(err);
	if (isLogin > 0) {
		return error;
	}
	else {
		if (err == 401) {
			// Vue.swal.fire({
			// 	title: 'Session Expired',
			// 	text: 'Your session has expired. Would you like to be redirected to the login page?',
			// 	type: 'warning',
			// 	showCancelButton: false,
			// 	confirmButtonColor: '#3085d6',
			// 	cancelButtonColor: '#d33',
			// 	confirmButtonText: 'Re-login'
			// }).then((result) => {
			// 	if (result.value) {
			// 		store.dispatch('auth/doLogout');
			// 		router.push('/login');
			// 		//window.location = '/login';                            
			// 	}
      // });
      // store.dispatch('auth/doLogout');
			// router.push('/login');
		} else {
			// Vue.swal.fire({
			// 	type: 'error',
			// 	title: 'Failed getting data',
			// 	text: error.response.data.message,
			// 	timer: 2000
			// });
			return Promise.reject(error);
		}
	}
});

export default myAPI;