<template>
  <ion-page>
    <ion-tabs>
      <ion-tab-bar class="tab-container" bottom="bottom">
        <ion-tab-button class="tab-button" tab="dashboard" href="/dashboard">
          <ion-icon class="tab-icon" :icon="home" />
          <ion-label class="tab-label">Dashboard</ion-label>
        </ion-tab-button> 
        <ion-tab-button class="tab-button" tab="profile" href="/profile">
          <ion-icon class="tab-icon" :icon="personCircle" />
          <ion-label class="tab-label">Profile</ion-label>
        </ion-tab-button>
        <ion-tab-button class="tab-button" tab="history" href="/history">
          <ion-icon class="tab-icon" :icon="calendarOutline" />
          <ion-label class="tab-label">My Day</ion-label>
        </ion-tab-button>
        <ion-tab-button class="tab-button" tab="emergency" href="/emergency">
          <ion-icon class="tab-icon" name="medical" />
          <ion-label class="tab-label">Emergency</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script>
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage } from '@ionic/vue';
import { home, calendarOutline, personCircle } from 'ionicons/icons';

export default {
  name: 'Tabs',
  components: { IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage },
  setup() {
    return {
      home,
      calendarOutline,
      personCircle
    }
  }
}
</script>
<style scoped> 
  .tab-label {
    display: none;
  }

  @media only screen and (min-width: 375px) {
    .tab-label {
      display: block;
    }
  }
</style>