const SET_CURRENT_PROFILE = (state, payload) => {
	const stringified_payload = JSON.stringify(payload);
	state.userProfile = stringified_payload;
};

const GET_USER_UUID = (state, payload) => {
	const stringified_payload = JSON.stringify(payload);
	state.userNotifID = stringified_payload;
};

export default {
  SET_CURRENT_PROFILE,
	GET_USER_UUID
}